.swiper-button-prev {
    position: absolute;
    left: 58px;
    right: auto;
    top: 50%;
    transform: translate(0, -50%);
    border: 1px solid var(--gray-300-color);
    border-radius: 4px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    width: 58px;
    box-sizing: border-box;
    background: url("/icons/slider-arrow-left.svg") no-repeat var(--white-color);
    background-position: center center;
}

.swiper-button-next {
    position: absolute;
    right: 58px;
    left: auto;
    top: 50%;
    transform: translate(0, -50%);
    border: 1px solid var(--gray-300-color);
    border-radius: 4px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    width: 58px;
    box-sizing: border-box;
    background: url("/icons/slider-arrow-right.svg") no-repeat var(--white-color);
    background-position: center center;
}

.swiper-pagination {
    position: absolute;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    top: auto;
    bottom: 124px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;

    .swiper-pagination-bullet {
        width: 24px;
        height: 4px;
        display: inline-block;
        background-color: var(--white-color);

        &.swiper-pagination-bullet-active {
            background-color: var(--primary-green-color);
        }
    }
}

@media (max-width: 1023px) {

    .swiper-pagination {
        bottom: 68px;
    }
}