.write-review-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .write-review-body {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .write-review-header {
            margin: 0;
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--h-line-height);
            font-size: 20px;
            color: var(--black-color);
        }

        .write-review-text-field {
            min-height: 280px;
            width: 100%;
        }

        .picture-button {
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding: 8px 12px;
            width: fit-content;
            height: fit-content;
        }
    }
}