.letter-detail-desc {
  margin: 40px 0;

  .mun-prd-list {
    display: block;
    padding: 0 0 10px;
    box-sizing: border-box;
  }

  img {
    width: 100%;
    max-width: 840px;
  }

  a {
    word-wrap: break-word;
  }
}


@media (max-width: 1023px) {

  .letter-detail-desc {
    margin: 12px 0 40px 0;
    
  
    .mun-prd-list {
      display: block;
      padding: 0 0 10px;
      box-sizing: border-box;
    }

    img {
      width: 100%;
    }
  }
}