.question-list-view {

    margin: 0;
    padding: 0;
    border-top: 2px solid var(--black-color);

    .question-list-item {
        margin: 0;
        padding: 0;
        .question-item-view {
            display: flex;
            flex-direction: row;
            padding: 24px;
            align-items: center;

            .question-item-filter-view {
                width: 120px;

                .question-item-filter {
                    background-color: var(--gray-900-color);
                    border-radius: 4px;
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 12px;
                    color: var(--white-color);
                    padding: 3px 12px;
                    width: fit-content;
                }
            }

            .question-item-title {
                font-family: "Pretendard Regular";
                font-weight: var(--regular-font-weight);
                font-size: 16px;
                line-height: var(--body-line-height);
            }
        }

        .question-item-content {
            padding: 24px;
            padding-left: 124px;
            background-color: var(--gray-200-color);

            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
            box-sizing: border-box;
        }
    }
}


@media (max-width: 1023px) {

    .question-list-view {
        .question-list-item {
            .accordion-header-view {
                border-bottom: 1px solid var(--gray-400-color);
            }

            .question-item-view {
                display: flex;
                flex-direction: column;
                gap: 2px;
                padding: 16px;
                padding-right: 0;
                align-items: start;
    
                .question-item-filter-view {
                    width: fit-content;
    
                    .question-item-filter {
                        font-size: 12px;
                        padding: 0 10px;
                        box-sizing: border-box;
                    }
                }
    
                .question-item-title {
                    font-size: 14px;
                }
            }

            .accordion-arrow {
                margin: 24px 16px !important; 
            }
    
            .question-item-content {
                padding: 24px 16px;
                background-color: var(--gray-200-color);
                border-bottom: 1px solid var(--gray-400-color);
                margin: 0;
            }
        }
    }
}