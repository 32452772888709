.modify-user-view {
    display: flex;
    flex-direction: column;
    gap: 40px;
    border-top: 2px solid var(--black-color);

    .modify-user-form-field {
        display: flex;
        flex-direction: column;

        .modify-user-input-field {
            padding: 16px 0;
            display: flex;
            flex-direction: row;
            gap: 24px;
            align-items: center;
            justify-content: stretch;

            &.last-field {
                border-bottom: 1px solid var(--gray-300-color);
            }

            .modify-user-input-field-title {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 16px;
                color: var(--black-color);
                width: 180px;

                .input-required {
                    color: var(--primary-green-color);
                }
            }

            .modify-user-input-field-body {
                display: flex;
                flex-direction: row;
                gap: 24px;
                align-items: center;
                .modify-user-input-field-desc {
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 16px;
                    color: var(--black-color);
                }
            }

            .modify-user-input-field-content {
                display: flex;
                flex-direction: column;
            }

            .modify-user-input {
                width: 360px;
                padding: 8px 16px;
            }

            .modify-user-input-wrapper {
                position: relative;
                width: 360px;
            }

            .modify-user-date-input {
                width: 100%;
                padding: 8px 16px;
            }

            .pull-down-icon {
                width: 24px;
                height: 24px;
                color: var(--gray-800-color);
    
                position: absolute;
                top: 7px;
                right: 15px;
            }

            .coupon-text {
                color: var(--primary-green-color);
            }
        }

        .modify-button {
            padding: 8px 12px;
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-700-color);
        }

        .modify-user-checkbox-input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;

            .checkbox-label {
                color: var(--black-color);
            }
        }
    
        .arrow-right {
            width: 24px;
            height: 24px;
        }
    
        .confirm-button {
            margin-top: 40px;
        }
    }

    .modify-user-footer-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 14px;

        .modify-user-footer-desc {
            color: var(--gray-600-color);
        }

        .modify-user-footer-button {
            color: var(--gray-800-color);
            text-decoration: underline;
            padding: 4px;
        }
    }

    .modal-panel {
        z-index: 201;
        position: fixed;
        top: 50%;
        transform: translate(0, -50%);
        margin: auto;
        width: 560px;
        height: fit-content;
        vertical-align: middle;
        background-color: var(--white-color);
        padding: 24px 40px 40px 40px;
        box-sizing: border-box;
        box-shadow: 0 4px 8px 0 rgb(0, 0, 0, 0.12);
        border-radius: 8px;

        .modal-headline {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            margin-bottom: 24px;

            .modal-title {
                margin: 0;
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--h-line-height);
                font-size: 20px;
                color: var(--gray-900-color);
            }
    
            .modal-close {
                padding: 0;
                width: 24px;
                height: 24px;
                border: 0;
                background-color: var(--white-color);
            }
        }

        .modify-phone-desc {
            margin: 0;
            margin-bottom: 16px;
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-900-color);
        }

        .request-verify-input-field {
            margin: 0;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
        }

        .request-verify-button {
            margin-left: 8px;
            min-width: 90px;
        }

        .verify-code-input-field {
            margin-top: 8px;
            position: relative;
        }
    
        .verify-code-input {
            width: 100%;
        }
    
        .count-down-text {
            position: absolute;
            top: 19px;
            right: 16px;
            font-size: 16px;
            color: var(--secondary-blue-color);
        }
        .verify-empty-field {
            height: 104px;
            width: 100%;
        }
    
        .register-error-text {
            margin: 8px 0;
        }
    
        .resend-verify-request {
            text-align: left;
            margin-top: 8px;

            .resend-button {
                width: fit-content;
            }
        }
    
        .confirm-button {
            margin-top: 40px;
        }

        .change-password-form-field {

            gap: 16px;
            display: flex;
            flex-direction: column;

            .password-input-field {
                position: relative;
            }
        
            .password-input {
                width: 100%;
            }
        
            .toggle-show-password-button {
                position: absolute;
                top: 16px;
                right: 16px;
            }

            .confirm-button {
                margin-top: 24px;
            }
        }

        .delete-account-content {
            gap: 16px;
            display: flex;
            flex-direction: column;

            .delete-account-subtitle {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 16px;
                color: var(--gray-600-color);
                margin: 0;
            }

            .delete-account-policy-list {
                padding-inline-start: 20px;
                margin: 0;

                .delete-account-policy-list-item {
                    list-style-type: disc;
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 16px;
                    color: var(--gray-900-color);
                }
            }

            .modal-checkbox-label {
                color: var(--black-color);
            }
        }

        .modal-button-group {
            display: flex;
            flex-direction: row;
            gap: 8px;
            margin-top: 40px;

            .cancel-button {
                margin: 0;
                flex: 1;
            }

            .confirm-button {
                margin: 0;
                flex: 1;
            }
        }
    }
}

@media (max-width: 1023px) {

    .modify-user-view {
        gap: 24px;
    
        .modify-user-form-field {
    
            .modify-user-input-field {
                padding: 16px 16px 16px 0;
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: stretch;
                justify-content: flex-start;
    
                &.last-field {
                    border-bottom: none;
                }
    
                .modify-user-input-field-title {
                    width: fit-content;
                }

                .modify-user-input-field-body {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                }

                .modify-user-input {
                    width: 100%;
                }
    
                .modify-user-input-wrapper {
                    position: relative;
                    width: 100%;
                }
            }
    
            .modify-button {
                width: fit-content;
            }
    
            .modify-user-checkbox-input-field {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 40px;
    
                .checkbox-label {
                    color: var(--black-color);
                }
            }
        
            .arrow-right {
                width: 24px;
                height: 24px;
            }
        
            .confirm-button {
                margin-top: 40px;
            }
        }
    
        .modify-user-footer-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
    
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
    
            .modify-user-footer-desc {
                color: var(--gray-600-color);
            }
    
            .modify-user-footer-button {
                color: var(--gray-800-color);
                text-decoration: underline;
                padding: 4px;
            }
        }
    
        .modal-panel {
            width: calc(100% - 48px);
            background-color: var(--white-color);
            padding: 24px 16px;
    
            .modal-headline {
    
                .modal-title {
                    line-height: var(--body-line-height);
                    font-size: 16px;
                }
        
                .modal-close {
                    padding: 0;
                    width: 24px;
                    height: 24px;
                    border: 0;
                    background-color: var(--white-color);
                }
            }
    
            .modify-phone-desc {
                font-size: 12px;
            }
    
            .delete-account-content {
    
                .delete-account-policy-list {
                    padding-inline-start: 20px;
                    margin: 0;
    
                    .delete-account-policy-list-item {
                        font-size: 14px;
                    }
                }
    
                .modal-checkbox-label {
                    color: var(--black-color);
                }
            }
    
            .modal-button-group {
                margin-top: 24px;
            }
        }
    }
}