.notice-board-view {
    .notice-board-table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
            height: 2px;
            background-color: var(--black-color);
        }

        tbody {
            tr {
                height: 72px;
                border-bottom: 1px solid var(--gray-400-color);
                cursor: pointer;

                &.on-top {
                    background-color: var(--gray-200-color);
                }

                td {
                    padding: 0;
                    border: 0;
                }

                .notice-pinned-cell {
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: 30px;
                    margin-right: 8px;
                    width: 24px;
                    height: 24px;
                    position: relative;
                    
                    .notice-pinned-image {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .notice-title-cell {
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 16px;
                    color: var(--gray-900-color);
                    margin: auto 16px;
                    height: 100%;

                    &.bold {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                    }
                }

                .notice-date-cell {
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-500-color);
                    margin: auto 24px;
                    height: 100%;
                }
            }
        }
    }
}

@media (max-width: 1023px) {

    .notice-board-view {
        .notice-board-table {
    
            tbody {
                tr {
                    height: fit-content;
    
                    .notice-pinned-cell {
                        margin-left: 10px;
                        margin-right: 12px;
                        width: 20px;
                        height: 20px;
                    }
    
                    .notice-title-cell {
                        
                        margin: 16px 0;
                        height: fit-content;
                        display: flex;
                        flex-direction: column;

                        .notice-title {
                            font-family: "Pretendard Regular";
                            font-weight: var(--regular-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 16px;
                            color: var(--gray-900-color);

                            &.bold {
                                font-family: "Pretendard Bold";
                                font-weight: var(--bold-font-weight);
                                line-height: var(--body-line-height);
                            }
                        }

                        .notice-date {
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                            color: var(--gray-500-color);
                        }
                    }
                }
            }
        }
    }
}