.payment-detail-view {
    display: flex;
    flex-direction: column;
    gap: 80px;

    dl {
        margin: 0;

        dt {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-900-color);
            padding: 4px 16px;
            width: 160px;
            box-sizing: border-box;
            float: left;
        }
    
        dd {
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-700-color);
            padding: 4px 0;
            margin-left: 160px;
        }
    }

    .payment-detail-header {
        border-top: 2px solid var(--gray-900-color);
        padding-top: 16px;
        padding-bottom: 8px;
    }

    .payment-detail-detergent-table-view {
        display: flex;
        flex-direction: column;
    }

    .payment-detail-footer {
        display: flex;
        flex-direction: column;

        .total-price-view {
            border-top: 2px solid var(--gray-900-color);
            border-bottom: 1px solid var(--gray-900-color);
            display: flex;
            flex-direction: row;
            .total-price-title {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--h-line-height);
                font-size: 20px;
                color: var(--gray-900-color);
                padding: 16px;
                width: 160px;
                box-sizing: border-box;
            }
            .total-price-desc {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--h-line-height);
                font-size: 20px;
                color: var(--primary-green-color);
                padding: 16px 0;
            }
        }
    }

    .payment-detail-subtitle {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 32px;
        color: var(--black-color);
        margin: 0;
        margin-bottom: 16px;
    }
}


@media (max-width: 1023px) {

    .payment-detail-view {
    
        .payment-detail-header {
            padding-bottom: 0;

            .payment-detail-title {
                font-size: 14px;
                padding: 4px 0;
                width: fit-content;
                float: none;
            }
        
            .payment-detail-desc {
                font-size: 14px;
                padding: 0 0 4px 0;
                margin-left: 0;
            }
        }
    
        .payment-detail-detergent-table-view {
            display: flex;
            flex-direction: column;

            .purchase-detergent-table {
                border-top: 2px solid var(--black-color);
                padding: 0;

                .detergent-item {
                    padding: 24px 0;
                    border-bottom: 1px solid var(--gray-300-color);
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 16px;

                    .detergent-item-image {
                        width: 64px;
                        height: 64px;
                    }

                    .detergent-item-content {
                        display: flex;
                        flex-direction: column;

                        .detergent-item-title-line {
                            display: flex;
                            flex-direction: row;
                            gap: 4px;

                            .detergent-item-title {
                                font-family: "Pretendard Bold";
                                font-weight: var(--bold-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 14px;
                                color: var(--gray-700-color);
                            }

                            .detergent-item-subtitle {
                                font-family: "Pretendard Regular";
                                font-weight: var(--regular-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 14px;
                                color: var(--gray-700-color);
                            }
                        }

                        .detergent-item-desc-line {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 8px;

                            .detergent-item-capacity {
                                font-family: "Pretendard Regular";
                                font-weight: var(--regular-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 12px;
                                color: var(--gray-700-color);
                            }

                            .vertical-divider {
                                width: 1px;
                                height: 12px;
                                background-color: var(--gray-300-color);
                            }

                            .detergent-item-manufacture {
                                font-family: "Pretendard Regular";
                                font-weight: var(--regular-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 12px;
                                color: var(--gray-700-color);
                            }
                        }

                        .detergent-item-detail-line {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 8px;

                            .detergent-item-detail-title {
                                font-family: "Pretendard Regular";
                                font-weight: var(--regular-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 12px;
                                color: var(--gray-700-color);
                            }

                            .detergent-item-detail-desc {
                                font-family: "Pretendard Regular";
                                font-weight: var(--regular-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 12px;
                                color: var(--gray-700-color);

                                &.active {
                                    font-family: "Pretendard Medium";
                                    font-weight: var(--medium-font-weight);
                                    padding: 2px 8px;
                                    color: var(--gray-900-color);
                                    border-radius: 4px;
                                    background-color: var(--gray-300-color);
                                }
                            }
                        }

                        .detergent-price {
                            font-family: "Pretendard Bold";
                            font-weight: var(--bold-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                            color: var(--gray-900-color);
                        }
                    }
                }
            }
        }
    
        .payment-detail-footer {
    
            .total-price-view {
                .total-price-title {
                    padding: 16px 0;
                    width: 144px;
                }
            }
        }
    
        .payment-detail-subtitle {
            font-size: 20px;
            margin-bottom: 24px;
        }

        .payment-detail-cost-view {
            margin-top: 16px;
            .payment-detail-cost-title {
                padding: 4px 0;
                width: 144px;
            }
        
            .payment-detail-cost-desc {
                margin-left: 144px;
            }
        }
    }
}