.footer {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: fit-content;
    z-index: 100;
    padding: 48px 156px;
    margin: 0;
    box-sizing: border-box;
    background-color: var(--black-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .footer-contents {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;

        .footer-logo {
            height: 16px;
            .footer-logo-image {
                width: 110px;
                height: 16px;
            }
            margin-bottom: 20px;
        }

        .footer-terms {
            display: flex;
            gap: 4px;

            .vertical-divider {
                width: 0px;
                height: 12px;
                margin: auto 0;
                border-left: 1px solid var(--gray-600-color);
            }

            .footer-usage {
                font-family: 'Pretendard Bold';
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-300-color);
                text-decoration: none;
            }
        }

        .footer-info {
            font-family: 'Pretendard Regular';
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-500-color);
            display: flex;
            gap: 8px;
        }
    }

    .footer-second-contents {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .footer-call-center-header {
            display: flex;
            flex-direction: column;

            .footer-call-center-title {
                font-family: 'Pretendard Regular';
                font-weight: var(--regular-font-weight);
                line-height: var(--body-line-height);
                font-size: 12px;
                color: var(--gray-500-color);
            }

            .footer-call-center-content {
                font-family: 'Pretendard Bold';
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 16px;
                color: var(--gray-300-color);
            }

            .footer-call-center-time {
                font-family: 'Pretendard Regular';
                font-weight: var(--regular-font-weight);
                line-height: var(--body-line-height);
                font-size: 12px;
                color: var(--gray-500-color);
            }
        }

        .footer-call-center-button {
            padding: 7px 12px;
            height: fit-content;

            .footer-call-center-button-content {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 24px;

                .footer-call-center-button-text {
                    font-family: 'Pretendard Medium';
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 16px;
                    color: var(--gray-700-color);
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .footer {
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .footer-contents {
            .footer-logo {
                margin-bottom: 16px;
            }

            .footer-terms {
                .footer-usage {
                    font-size: 12px;
                }
            }
        }
    }

    .footer-second-contents {

        .footer-call-center-header {

            .footer-call-center-content {
                font-size: 14px;
            }
        }
    }
}