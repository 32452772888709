.purchase-history-list {
    .my-page-purchase-history-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
  
        .purchase-history-button {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px;
            gap: 2px;
  
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
            color: var(--gray-800-color);
            text-decoration: none;
        }
    }
  
    .purchase-history-table {
        margin-top: 16px;
        width: 100%;
        border-top: 2px solid var(--black-color);
        border-spacing: 0;
        border-collapse: collapse;
        thead {
            border-bottom: 1px solid var(--gray-900-color);
            height: 66px;
        }
        th {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
            color: var(--gray-900-color);
            text-align: center;
        }
  
        .purchase-date-column {
            padding: 12px 24px;
            width: 160px;
        }
  
        .purchase-manufacture-date-column {
            padding: auto 16px;
            width: 120px;
        }
  
        .purchase-bottle-column {
            padding: auto 16px;
            width: 120px;
        }
  
        .purchase-price-column {
            padding: auto 16px;
            width: 120px;
        }
  
        .purchase-review-column {
            width: 76px;
        }
  
        .purchase-history-row {
            &.last-row {
                border-bottom: 1px solid var(--gray-400-color);
            }
            &.clickable {
                cursor: pointer;
            }
        }
  
        .purchase-history-row:not(.last-row) {
            border-bottom: 1px solid var(--gray-300-color);
        }
  
        .purchase-date-cell {
            padding: 32px 24px;
            .purchase-date-text {
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-500-color);
            }
            .purchase-spot {
                margin-top: 4px;
                padding: 2px 8px;
                border-radius: 4px;
                background-color: var(--gray-300-color); 
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 12px;
                color: var(--gray-900-color);
                text-overflow: ellipsis;
            }
        }
  
        .purchase-item-cell {
            display: flex;
            flex-direction: row;
            gap: 16px;
            height: 76px;
            align-items: center;
            margin: 16px 0;
  
            .purchase-item-header {
                display: flex;
                flex-direction: column;
  
                .purchase-item-title-line {
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    .purchase-item-title {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                        color: var(--gray-700-color);
                    }
  
                    .purchase-item-subtitle {
                        font-family: "Pretendard Regular";
                        font-weight: var(--regular-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                        color: var(--gray-700-color);
                    }
                }
  
                .purchase-item-desc {
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 16px;
                    color: var(--gray-700-color);
                }
            }
  
            &.first-row {
                margin-top: 32px;
            }
  
            &.last-row {
                margin-bottom: 32px;
            }
        }

        .purchase-manufacture-date-cell {
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-700-color);
            text-align: center;
  
            &.first-row {
                padding-top: 16px;
            }
  
            &.last-row {
                padding-bottom: 16px;
            }
        }

        .purchase-bottle-cell {
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-900-color);
            text-align: center;
  
            &.first-row {
                padding-top: 16px;
            }
  
            &.last-row {
                padding-bottom: 16px;
            }

            div {
                display: flex;
                flex-direction: row;
                justify-content: center;

                span {
                    background-color: var(--gray-300-color);
                    border-radius: 4px;
                    width: fit-content;
                    padding: 2px 8px;
                }
            }
        }
  
        .purchase-price-cell {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-900-color);
            text-align: center;
  
            &.first-row {
                padding-top: 16px;
            }
  
            &.last-row {
                padding-bottom: 16px;
            }
        }
  
        .button-group {
            display: flex;
            flex-direction: column;
            width: 76px;
            height: 76px;
            gap: 4px;
            padding: 16px 0;
        }
  
        .table-button {
            padding: 7.5px 11px;
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
        }
    }
  
    .page-number-view {
      margin-top: 44px;
    }
}


@media (max-width: 1023px) {

    .purchase-history-list {
        margin: 24px 0 0 0;
        padding: 0;

        .purchase-history-block {
            display: flex;
            flex-direction: column;
            border-top: 2px solid var(--black-color);

            .purchase-info-cell {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid var(--gray-300-color);

                .purchase-date {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                    padding: 16px 0;

                    .purchase-date-text {
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 14px;
                        color: var(--gray-500-color);
                    }

                    .purchase-spot {
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-900-color);
                        padding: 2px 8px;
                        background-color: var(--gray-300-color);
                        border-radius: 4px;
                    }
                }

                .button-group {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    height: fit-content;
                    gap: 4px;
                    padding: 16px 0;
                }
    
                .table-button {
                    padding: 7.5px 12px;
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                }
            }

            .purchase-item-cell {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: center;
                margin: 0;
                padding: 24px 0;

                .purchase-item-header {
                    display: flex;
                    flex-direction: column;

                    .purchase-item-title-line {
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                        .purchase-item-title {
                            font-family: "Pretendard Bold";
                            font-weight: var(--bold-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                            color: var(--gray-700-color);
                        }
    
                        .purchase-item-subtitle {
                            font-family: "Pretendard Regular";
                            font-weight: var(--regular-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                            color: var(--gray-700-color);
                        }
                    }

                    .purchase-item-desc {
                        font-family: "Pretendard Regular";
                        font-weight: var(--regular-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-700-color);
                    }

                    .purchase-price {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 14px;
                        color: var(--gray-900-color);
                    }
                }
            }
        }
    }
}