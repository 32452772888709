.event-detail-view {
  padding: 80px 156px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .event-detail-title {
      font-family: "Pretendard Bold";
      font-weight: var(--bold-font-weight);
      line-height: var(--h-line-height);
      font-size: 32px;
      color: var(--black-color);
      margin-bottom: 8px;
      width: 100%;
  }

  .event-detail-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 8px;

    .event-list-item-progress {
      padding: 4px 8px;
      font-family: "Pretendard Medium";
      font-weight: var(--medium-font-weight);
      line-height: var(--body-line-height);
      font-size: 12px;
  
      &.processing {
        background-color: var(--primary-green-color);
        color: var(--black-color);
      }
  
      &.finished {
        background-color: var(--black-color);
        color: var(--white-color);
      }
    }

    .event-detail-create-date {
      font-family: "Pretendard Medium";
      font-weight: var(--medium-font-weight);
      line-height: var(--body-line-height);
      font-size: 14px;
      color: var(--gray-800-color);
    }
  }

  .event-detail-content {
      width: 100%;
      margin-top: 32px;
      padding: 40px 0;
      border-top: 1px solid var(--gray-900-color);
      border-bottom: 1px solid var(--gray-900-color);
      display: flex;
      flex-direction: column;

      .event-detail-image {
        margin: 0 96px;
      }

      .event-detail-desc {
        margin: 32px 0;
      }

      .event-detail-link-button {
        width: 100%;
      }
  }

  .event-detail-buttons {
      margin: 40px 0;
      padding: 16px auto;
      width: 480px;

      .event-detail-button {
          width: 100%;
      }
  }
}


@media (max-width: 1023px) {
  .event-detail-view {
    padding: 80px 24px 40px 24px;
  
    .event-detail-title {
        line-height: var(--body-line-height);
        font-size: 18px;
        color: var(--black-color);
        margin-bottom: 2px;
    }
  
    .event-detail-subheader {
  
      .event-list-item-progress {
        padding: 2px 8px;
      }
  
      .event-detail-create-date {
        font-family: "Pretendard Regular";
        font-weight: var(--regular-font-weight);
        line-height: var(--body-line-height);
        font-size: 12px;
      }
    }
  
    .event-detail-content {
        margin-top: 16px;
  
        .event-detail-image {
          margin: 0;
        }
  
        .event-detail-link-button {
          width: 100%;
        }
    }
  
    .event-detail-buttons {
        margin: 0;
        margin-top: 32px;
        width: 100%;
    }
  }
}