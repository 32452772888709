.register-view {
    max-width: 480px;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font: inherit;
    position: relative;
    vertical-align: baseline;
    margin: auto;
    min-height: calc(100vh - 257px);

    .register-title {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 40px;
        color: var(--black-color);
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 80px 0;
    }

    .register-desc {
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 16px;
        color: var(--black-color);
        text-align: left;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .request-verify-input-field {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
    }

    .request-verify-button {
        margin-left: 8px;
        min-width: 90px;
    }

    .verify-code-input-field {
        margin-top: 8px;
        position: relative;
    }

    .verify-code-input {
        width: 100%;
    }

    .count-down-text {
        position: absolute;
        top: 19px;
        right: 16px;
        font-size: 16px;
        color: var(--secondary-blue-color);
    }

    .register-error-text {
        font-size: 14px;
        margin: 8px 0;
    }

    .resend-verify-request {
        text-align: left;
        margin-top: 16px;
    }

    .confirm-button {
        margin-top: 16px;
    }

    .register-form-field {
        gap: 16px;
        display: flex;
        flex-direction: column;
    }

    .password-input-field {
        position: relative;
    }

    .password-input {
        width: 100%;
    }

    .toggle-show-password-button {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .date-input-field {
        position: relative;

        .pull-down-icon {
            width: 24px;
            height: 24px;
            color: var(--gray-800-color);

            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    .coupon-text {
        color: var(--primary-green-color);
    }

    .checkbox-area {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .register-checkbox-input-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
    }

    .arrow-right {
        width: 24px;
        height: 24px;
    }

    .register-complete-view {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .complete-image {
        margin-top: 20vh;
        width: 280px;
        height: 180px;
        margin-bottom: 16px;
    }

    .welcome-title {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 40px;
        color: var(--black-color);
        margin: 0;

        .welcome-user-name {
            color: var(--primary-green-color);
        }
    }

    .welcome-desc {
        font-family: "Pretendard Regular";
        font-weight: var(--regular-font-weight);
        line-height: var(--body-line-height);
        font-size: 18px;
        color: var(--gray-800-color);
    }

    .confirm-button-field {
        margin-bottom: 40px;
        width: 100%;
    }
}

@media (max-width: 1023px) {
    .register-view {
        width: calc(100% - 48px);
        min-height: calc(100vh - 246px);

        .register-title {
            font-size: 24px;
            padding: 0;
            margin: 80px 0 40px 0;
        }
        
        .complete-image {
            margin-top: 80px;
            width: 196px;
            height: 126px;
        }
    
        .welcome-title {
            line-height: var(--body-line-height);
            font-size: 18px;
        }
    
        .welcome-desc {
            font-size: 14px;
        }
    
        .confirm-button-field {
            margin-bottom: 40px;
            width: 100%;
        }
    }
}