.event-view {
  display: flex;
  flex-direction: column;
  
  .event-list-view {
    border-top: 2px solid var(--black-color);
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    overflow-x: auto;

    .event-list-item {
      width: calc(33.33% - 16px);
      flex-direction: column;
      text-decoration: none;

      .event-list-item-image-view {
        position: relative;
        margin-bottom: 16px;
        aspect-ratio: 1;
        box-sizing: border-box;

        .event-list-item-image {
          width: 100%;
          height: 100%;
        }

        .dimmed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 20;
          opacity: 0.5;
          background-color: #000000;
        }

        .event-list-item-progress {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 4px 8px;
          font-family: "Pretendard Medium";
          font-weight: var(--medium-font-weight);
          line-height: var(--body-line-height);
          font-size: 12px;
          z-index: 40;

          &.processing {
            background-color: var(--primary-green-color);
            color: var(--black-color);
          }

          &.finished {
            background-color: var(--black-color);
            color: var(--white-color);
          }
        }
      }

      .event-list-item-title {
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--h-line-height);
        font-size: 24px;
        color: var(--gray-900-color);
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .event-list-item-date {
        margin-top: 4px;
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 12px;
        color: var(--gray-800-color);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

@media (max-width: 1023px) {
  .event-view {
    
    .event-list-view {
      display: flex;
      flex-direction: column;
      gap: 24px;
  
      .event-list-item {
        width: 100%;
  
        .event-list-item-image-view {
          margin-bottom: 8px;
        }
  
        .event-list-item-title {
          font-family: "Pretendard Bold";
          font-weight: var(--bold-font-weight);
          line-height: var(--body-line-height);
          font-size: 16px;
        }
  
        .event-list-item-date {
          font-family: "Pretendard Regular";
          font-weight: var(--regular-font-weight);
        }
      }
    }
  }
}