.page-number-view {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .page-number-item {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Pretendard Medium";
        font-size: 16px;
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        text-decoration: none;
        color: var(--gray-500-color);

        .left-arrow {
            width: 24px;
            height: 24px;
            transform: scaleX(-1);
        }

        .right-arrow {
            width: 24px;
            height: 24px;
        }

        &.selected {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            color: var(--gray-800-color);
            text-decoration: underline;
        }
    }
}

.accordion-view {
    .accordion-header-view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;

        .accordion-arrow {
            width: 24px;
            height: 24px;
            margin: 24px;

            &.is-open {
                transform: scaleY(-1);
            }
        }
    }

    .accordion-wrapper {
        height: 0;
        width: 100%;
        overflow: hidden;
        transition: height 0.35s ease;

        .accordion-content {
            width: 100%;
            height: fit-content;
        }
    }
}