.home-view {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  vertical-align: baseline;
  margin: auto;

  .home-title-header {
    font-family: "Pretendard Bold";
    font-weight: var(--bold-font-weight);
    line-height: var(--h-line-height);
    font-size: 24px;
    color: var(--primary-green-color);
    margin-top: 110px;
    margin-bottom: 4px;
  }

  .home-title {
    font-family: "Pretendard Bold";
    font-weight: var(--bold-font-weight);
    line-height: var(--h-line-height);
    font-size: 48px;
    color: var(--black-color);
    margin-bottom: 23px;
    text-align: center;
  }

  .main-banner {
    width: 100%;
    height: fit-content;
    z-index: 1;

    .banner-image {
      width: 100%;
      aspect-ratio: 1440/540;
    }
  }

  .search-banner {
    z-index: 2;
    position: relative;
    margin-top: -100px;
    box-sizing: border-box;
    height: 200px;
    margin-left: 156px;
    margin-right: 156px;
    width: calc(100% - 312px);
    padding: 44px 96px;
    background-color: var(--white-color);
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .search-banner-cover {
      pointer-events : none;
      position: absolute;
      bottom: 0;
      top: 100px;
      left: 0;
      right: 0;
      border-bottom: 10px solid var(--primary-green-color);
      border-left: 10px solid var(--primary-green-color);
      border-right: 10px solid var(--primary-green-color);
      border-radius: 0 0 4px 4px;
    }

    .search-banner-title {
      font-family: "Pretendard Bold";
      font-weight: var(--bold-font-weight);
      line-height: var(--h-line-height);
      font-size: 20px;
      color: var(--gray-900-color);
    }

    .search-banner-input-field {

      position: relative;
      width: 100%;

      .search-banner-input {
        width: 100%;
        padding: 17.5px 16px 17.5px 40px;
        font-family: "Pretendard Regular";
        font-weight: var(--regular-font-weight);
        line-height: var(--body-line-height);
        font-size: 14px;
        background-color: var(--gray-200-color);
        border: none;
        border-radius: 8px;
      }

      .search-icon {
        position: absolute;
        top: 20px;
        left: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .home-banner {
    padding: 120px 156px;
    width: 100%;
    box-sizing: border-box;

    &.background-banner {
      background-image: url("/images/banner-background.png");
      background-size: cover;
    }

    .home-banner-title {
      font-family: "Pretendard Bold";
      font-weight: var(--bold-font-weight);
      line-height: var(--h-line-height);
      font-size: 40px;
      color: var(--black-color);
      text-decoration: none;
    }

    .home-banner-desc {
      margin-top: 8px;
      font-family: "Pretendard Regular";
      font-weight: var(--regular-font-weight);
      line-height: var(--body-line-height);
      font-size: 18px;
      color: var(--black-color);
    }

    .how-to-use-banner-list {
      margin-top: 56px;
      display: inline-flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;

      .how-to-use-panel {
        position:relative;
        padding: 28px 40px;
        flex: 1;
        box-sizing: border-box;
        border-radius: 8px;
        border: 0;
        text-decoration: none;

        .how-to-use-panel-cover {
          position: absolute;
          bottom: 0;
          top: auto;
          height: 62px;
          left: 0;
          right: 0;
          border-bottom: 4px solid var(--black-color);
          border-left: 4px solid var(--black-color);
          border-right: 4px solid var(--black-color);
          border-radius: 0 0 4px 4px;
        }

        &.left-panel {
          background-color: var(--blue-20-color);
        }

        &.right-panel {
          background-color: var(--blue-20-color);
        }

        .how-to-use-panel-title {
          font-family: "Pretendard Bold";
          font-weight: var(--bold-font-weight);
          line-height: var(--h-line-height);
          font-size: 28px;
          color: var(--gray-900-color);
          margin-bottom: 8px;
        }

        .how-to-use-panel-desc {
          font-family: "Pretendard Regular";
          font-weight: var(--regular-font-weight);
          line-height: var(--body-line-height);
          font-size: 16px;
          color: var(--gray-900-color);
        }

        .how-to-use-panel-image {
          width: 100%;
          aspect-ratio: 16/9;
          margin-top: 16px;
        }
      }
    }

    .notice-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 56px;
      border-top: 2px solid var(--black-color);

      .notice-list-item {
        padding: 24px;
        background-color: var(--white-color);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--black-color);
        text-decoration: none;
        .notice-list-item-title {
          font-family: "Pretendard Medium";
          font-weight: var(--medium-font-weight);
          line-height: var(--body-line-height);
          font-size: 18px;
          color: var(--gray-900-color);
        }
        .notice-list-item-date {
          font-family: "Pretendard Medium";
          font-weight: var(--medium-font-weight);
          line-height: var(--body-line-height);
          font-size: 14px;
          color: var(--gray-500-color);
        }
      }
    }

    .notice-banner-button-field {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .notice-banner-button {
        width: 160px;
      }
    }

    &.horizontal-banner {
      display: flex;
      flex-direction: row;
    }

    .event-banner-title-view {
      min-width: calc(33.33% - 16px);
      margin-right: 24px;

      .event-banner-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px;
        gap: 2px;

        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 14px;
        color: var(--green-70-color);
        text-decoration: none;
      }
    }

    .event-list-view {
      display: flex;
      flex-direction: row;
      gap: 24px;
      overflow-x: auto;
      width: 100%;

      .event-list-item {
        display: flex;
        flex-direction: column;
        text-decoration: none;
        min-width: calc(50% - 12px);

        .event-list-item-image-view {
          position: relative;
          margin-bottom: 8px;
          width: 100%;
          aspect-ratio: 1;
          box-sizing: border-box;

          .event-list-item-image {
            border-radius: 8px 8px 0 0;
            width: 100%;
            height: 100%;
          }

          .event-list-item-image-view-border {
            position: absolute;
            top: auto;
            left: 0;
            bottom: 0;
            width: 100%;
            border-bottom: 2px solid var(--black-color);
            z-index: 40;
          }

          .dimmed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 20;
            opacity: 0.5;
            background-color: #000000;
          }

          .event-list-item-progress {
            position: absolute;
            bottom: 2px;
            left: 0;
            padding: 4px 16px;
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            z-index: 40;

            &.processing {
              background-color: var(--primary-green-color);
              color: var(--black-color);
            }

            &.finished {
              background-color: var(--black-color);
              color: var(--white-color);
            }
          }
        }

        .event-list-item-title {
          font-family: "Pretendard Medium";
          font-weight: var(--medium-font-weight);
          line-height: var(--h-line-height);
          font-size: 24px;
          color: var(--gray-900-color);
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .event-list-item-date {
          margin-top: 4px;
          font-family: "Pretendard Regular";
          font-weight: var(--regular-font-weight);
          line-height: var(--body-line-height);
          font-size: 14px;
          color: var(--gray-800-color);
        }
      }
    }
  }
}


@media (max-width: 1023px) {

  .home-view {

    .main-banner {
  
      .banner-image {
        aspect-ratio: 3/2;
      }
    }
  
    .search-banner {
      margin-top: -60px;
      height: 120px;
      margin-left: 24px;
      margin-right: 24px;
      width: calc(100% - 48px);
      padding: 24px;
      padding-bottom: 27px;
      gap: 4px;
  
      .search-banner-cover {
        pointer-events : none;
        position: absolute;
        bottom: 0;
        top: 60px;
        left: 0;
        right: 0;
        border-bottom: 4px solid var(--primary-green-color);
        border-left: 4px solid var(--primary-green-color);
        border-right: 4px solid var(--primary-green-color);
      }
  
      .search-banner-title {
        line-height: var(--body-line-height);
        font-size: 14px;
      }
  
      .search-banner-input-field {
  
        .search-banner-input {
          padding: 13px 16px 13px 40px;
          font-size: 12px;
        }
  
        .search-icon {
          position: absolute;
          top: 14px;
          left: 16px;
          width: 16px;
          height: 16px;
        }
      }
    }
  
    .home-banner {
      padding: 80px 24px;
  
      &.background-banner {
        background-image: url("/images/banner-background.png");
        background-size: cover;
      }
  
      .home-banner-title {
        font-size: 20px;
      }
  
      .home-banner-desc {
        margin-top: 4px;
        font-size: 14px;
      }
  
      .how-to-use-banner-list {
        margin-top: 32px;
        display: inline-flex;
        width: 100%;
        flex-direction: column;
        justify-content: start;
        gap: 16px;
  
        .how-to-use-panel {
          position:relative;
          padding: 24px;
          border: 0;
          text-decoration: none;
          box-sizing: border-box;
  
          .how-to-use-panel-cover {
            position: absolute;
            bottom: 0;
            top: auto;
            height: 62px;
            left: 0;
            right: 0;
            border-bottom: 2px solid var(--black-color);
            border-left: 2px solid var(--black-color);
            border-right: 2px solid var(--black-color);
            border-radius: 0 0 4px 4px;
          }
  
          &.left-panel {
            background-color: var(--blue-20-color);
          }
  
          &.right-panel {
            background-color: var(--blue-20-color);
          }
  
          .how-to-use-panel-title {
            line-height: var(--body-line-height);
            font-size: 16px;
            margin-bottom: 4px;
          }
  
          .how-to-use-panel-desc {
            font-size: 14px;
          }
  
          .how-to-use-panel-image {
            width: 100%;
            aspect-ratio: 16/9;
          }
        }
      }
  
      .notice-list {
        margin-top: 34px;
  
        .notice-list-item {
          padding: 16px 24px;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          .notice-list-item-title {
            font-size: 16px;
          }
          .notice-list-item-date {
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            font-size: 12px;
          }
        }
      }
  
      &.horizontal-banner {
        display: flex;
        flex-direction: column;
      }
  
      .event-banner-title-view {
        min-width: auto;
  
        .event-banner-button {
          margin-top: 4px;
          margin-bottom: 32px;
        }
      }
  
      .event-list-view {
        display: flex;
        flex-direction: column;
  
        .event-list-item {
          height: fit-content;
  
          .event-list-item-image-view {
            width: 100%;
            height: auto;
            aspect-ratio: 1;
  
            .event-list-item-image {
              width: 100%;
              height: 100%;
              border-radius: 0;
            }
  
            .event-list-item-image-view-border {
              border-bottom: none;
            }
  
            .event-list-item-progress {
              position: absolute;
              bottom: 0;
              left: 0;
              padding: 4px 8px;
              font-size: 12px;
            }
          }
  
          .event-list-item-title {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
          }
  
          .event-list-item-date {
            font-size: 12px;
          }
        }
      }
    }
  }
}