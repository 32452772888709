.notice-detail-view {
    padding: 80px 156px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .notice-detail-title {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 32px;
        color: var(--black-color);
        margin-bottom: 4px;
        width: 100%;
    }

    .notice-detail-create-date {
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 14px;
        color: var(--gray-500-color);
        width: 100%;
    }

    .notice-detail-content {
        width: 100%;
        margin-top: 32px;
        padding: 40px 0;
        border-top: 1px solid var(--gray-900-color);
        border-bottom: 1px solid var(--gray-900-color);
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 16px;
    }

    .notice-detail-buttons {
        margin: 40px 0;
        padding: 16px auto;
        width: 480px;

        .notice-detail-button {
            width: 100%;
        }
    }
}

@media (max-width: 1023px) {

    .notice-detail-view {
        padding: 80px 24px 40px 24px;
    
        .notice-detail-title {
            line-height: var(--body-line-height);
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 2px;
        }
    
        .notice-detail-create-date {
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            font-size: 12px;
        }
    
        .notice-detail-content {
            margin-top: 16px;
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
        }
    
        .notice-detail-buttons {
            margin: 0;
            margin-top: 40px;
            width: 100%;
        }
    }
}