@font-face {
  font-family: "Pretendard Bold";
  font-weight: 700;
  font-style: normal;
  src: url('./Pretendard-Bold.otf') format('opentype'),
      url('./Pretendard-Bold.woff') format('woff'),
      url('./Pretendard-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Pretendard Medium";
  font-weight: 500;
  font-style: normal;
  src: url('./Pretendard-Medium.otf') format('opentype'),
      url('./Pretendard-Medium.woff') format('woff'),
      url('./Pretendard-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Pretendard Regular";
  font-weight: 400;
  font-style: normal;
  src: url('./Pretendard-Regular.otf') format('opentype'),
      url('./Pretendard-Regular.woff') format('woff'),
      url('./Pretendard-Regular.ttf') format('truetype');
}

:root {
  --bold-font-weight: 700;
  --medium-font-weight: 500;
  --regular-font-weight: 400;

  --h-line-height: 130%;
  --body-line-height: 150%;
}