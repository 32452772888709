.detergent-list-view {
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  .detergent-filter-list {
    display: flex;
    flex-direction: row;
    gap: 4px;
    list-style: none;
    margin: 0;
    padding: 0;

    .detergent-filter-item {

      float: left;
      border-radius: 4px;
      border: 1px solid var(--gray-400-color);
      padding: 7.5px 12px;

      font-family: "Pretendard Medium";
      font-weight: var(--medium-font-weight);
      line-height: var(--body-line-height);
      font-size: 14px;
      color: var(--gray-700-color);

      &.selected {
        border-color: var(--black-color);
        background-color: var(--black-color);
        color: var(--white-color);
      }
    }
  }

  .detergent-list {
    border-top: 2px solid var(--black-color);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: 24px;

    .detergent-list-item {
      width: calc(50% - 12px);
      padding: 40px 0;

      .detergent-item-view {
        width: 100%;
        display: flex;
        flex-direction: column;

        .detergent-item-image-view {
          width: 100%;
          height: fit-content;
          position: relative;

          .detergent-item-image {
            width: 100%;
            aspect-ratio: 552 / 400;
            object-fit: fill;
          }

          .detergent-item-tag1 {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            padding: 4px 8px;

            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--white-color);
            background-color: var(--black-color);
          }
          .detergent-item-tag2 {
            position: absolute;
            left: 0;
            top: 32px;
            z-index: 1;
            padding: 4px 8px;

            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--white-color);
            background-color: var(--black-color);
          }
        }

        .detergent-item-title-header {
          margin-top: 12px;
          margin-bottom: 16px;
          padding-bottom: 12px;
          border-left: 1px solid var(--black-color);
          border-right: 1px solid var(--black-color);
          border-bottom: 1px solid var(--black-color);
          display: flex;
          flex-direction: column;
          align-items: center;

          .detergent-item-title {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--h-line-height);
            font-size: 24px;
            color: var(--black-color);
          }

          .detergent-item-subtitle {
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-500-color);
          }
        }

        .detergent-item-desc {
          font-family: "Pretendard Regular";
          font-weight: var(--regular-font-weight);
          line-height: var(--body-line-height);
          font-size: 16px;
          color: var(--gray-900-color);
          padding-bottom: 12px;
          border-bottom: 1px solid var(--gray-300-color);
          text-align: center;
        }

        dl {
          margin-top: 16px;
          margin-bottom: 12px;

          dt {
            float: left;
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-900-color);
            margin-bottom: 4px;
            width: 64px;
          }

          dd {
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-700-color);
            margin-left: 64px;
            margin-bottom: 4px;
          }
        }

        .detergent-item-footer {
          border-top: 1px solid var(--black-color);
          padding-top: 4px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .footer-title {
            font-family: "Pretendard Regular";
            font-weight: var(--regular-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-900-color);
          }

          .footer-desc {
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 12px;
            color: var(--gray-900-color);
          }
        }
      }
    }
  }
}


@media (max-width: 1023px) {

  .detergent-list-view {
  
    .detergent-list {
      border-top: 2px solid var(--black-color);
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 40px;
  
      .detergent-list-item {
        width: 100%;
        padding: 0;
  
        .detergent-item-view {
  
          .detergent-item-image-view {
  
            .detergent-item-image {
              width: 100%;
              aspect-ratio: calc(312 / 226);
            }
  
            .detergent-item-tag1 {
              font-size: 14px;
            }
            .detergent-item-tag2 {
              top: 28px;
              font-size: 14px;
            }
          }
  
          .detergent-item-title-header {
            margin-top: 8px;
            margin-bottom: 12px;
            padding-bottom: 12px;
            border-left: 1px solid var(--black-color);
            border-right: 1px solid var(--black-color);
            border-bottom: 1px solid var(--black-color);
  
            .detergent-item-title {
              line-height: var(--body-line-height);
              font-size: 16px;
            }
          }
  
          .detergent-item-desc {
            font-size: 14px;
          }
  
          dl {
            margin-top: 12px;
            margin-bottom: 8px;
          }
  
          .detergent-item-footer {
            border-top: 1px solid var(--black-color);
            padding-top: 4px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
  
            .footer-title {
              font-family: "Pretendard Regular";
              font-weight: var(--regular-font-weight);
              line-height: var(--body-line-height);
              font-size: 12px;
              color: var(--gray-900-color);
            }
  
            .footer-desc {
              font-family: "Pretendard Medium";
              font-weight: var(--medium-font-weight);
              line-height: var(--body-line-height);
              font-size: 12px;
              color: var(--gray-900-color);
            }
          }
        }
      }
    }
  }
}