.locate-main {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 78px);
}

.locate-view {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 78px);
    display: flex;

    .search-list-view {
        position: relative;
        padding: 33px 0 0 0;
        box-sizing: border-box;
        min-width: 400px;
        height: 100%;
        z-index: 100;

        .search-list-title {
            font-family: "Pretendard Bold";
            font-weight: var(--bold-font-weight);
            line-height: var(--h-line-height);
            font-size: 28px;
            color: var(--black-color);
            margin: 0 24px;
        }

        .search-input-field {
            margin: 12px 24px;
            position: relative;
            box-sizing: border-box;

            .search-input {
                font-size: 14px;
                width: 100%;
                border-color: var(--gray-900-color);
                padding: 13.5px 16px 13.5px 44px;
                box-sizing: border-box;
            }
    
            .search-icon {
                position: absolute;
                top: 12px;
                left: 16px;
                width: 24px;
                height: 24px;
            }
        }

        .filter-list-view {
            display: flex;
            flex-direction: row;
            gap: 4px;
            padding-left: 24px;

            .filter-item {
                border: 1px solid var(--gray-300-color);
                border-radius: 8px;
                box-sizing: border-box;
                padding: 7.5px 7px;
                font-family: "Pretendard Regular";
                font-weight: var(--regular-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-700-color);
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 2px;

                &.selected {
                    border-color: var(--primary-green-color);
                    background-color: var(--green-10-color);
                    color: var(--green-70-color);
                }

                &.multi-filter-item {
                    position: relative;
                    padding: 6px 8px;

                    .dropdown-list {
                        position: absolute;
                        top: 40px;
                        left: 0;
                        width: fit-content;
                        height: fit-content;
                        box-sizing: border-box;
                        background-color: var(--white-color);
                        border: 1px solid var(--gray-200-color);
                        border-radius: 8px;
                        padding: 8px 0;
                        display: flex;
                        flex-direction: column;
                        z-index: 150;
                        box-shadow: 2px 2px 16px 0 rgb(0, 0, 0, 0.08);
        
                        .dropdown-text {
                            text-decoration: none;
                        }
        
                        .dropdown-menu {
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--h-line-height);
                            font-size: 16px;
                            color: var(--gray-900-color);
                            padding: 0 16px;
                            width: 160px;
                            height: 40px;
                        }
                    }
                }
            }
        }

        .search-list-scroll {
            overflow-y: auto;
            height: calc(100vh - 252.5px);

            .search-list-empty {
                height: 100%;
                padding-right: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;

                .search-list-empty-title {
                    font-family: "Pretendard Bold";
                    font-weight: var(--bold-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 18px;
                    color: var(--gray-900-color);
                }

                .search-list-empty-desc {
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-600-color);
                }
            }
        }

        .search-list-item {
            box-sizing: border-box;
            padding: 24px;
            border-bottom: 1px solid var(--gray-300-color);

            .search-item-title-field {

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .search-item-title {
                    font-family: "Pretendard Bold";
                    font-weight: var(--bold-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 18px;
                    color: var(--black-color);
                    margin: 0;
                    cursor: pointer;
                }

                .search-item-favorite {
                    width: 32px;
                    height: 32px;
                    box-sizing: border-box;
                    padding: 4px;
                    display: block;
                    z-index: 105;
                }
            }

            .search-item-desc {
                font-family: "Pretendard Regular";
                font-weight: var(--regular-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-800-color);
                margin: 0;
                cursor: pointer;
            }

            .search-item-detergent-list {
                display: flex;
                flex-direction: row;
                gap: 4px;
                margin-top: 4px;
                cursor: pointer;

                .detergent-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 2px;
                    padding: 4px 8px;
                    border-radius: 8px;
                    background-color: var(--gray-200-color);

                    .detergent-item-text {
                        font-family: "Pretendard Regular";
                        font-weight: var(--regular-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-800-color);
                    }

                    .detergent-item-count {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-800-color);
                    }
                }
            }
        }
    }

    .search-map-view {
        width: 100%;
        height: 100%;

        .marker-box {
            position: relative;
            left: -50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            .marker-title {
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                padding: 2px 4px;
                background-color: var(--white-color);
                border: 1px solid var(--primary-green-color);
                border-radius: 4px;
                white-space : nowrap;
            }
        }
    }
}

.detail-modal-panel {
    z-index: 201;
    position: fixed;
    top: 50%;
    transform: translate(0, -50%);
    margin: auto;
    width: fit-content;
    height: fit-content;
    max-height: 100vh;
    box-sizing: border-box;
    background-color: var(--white-color);
    padding: 24px 40px 42px 40px;
    box-shadow: 0 4px 8px 0 rgb(0, 0, 0, 0.12);
    border-radius: 8px;

    .detail-modal-headline {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        margin-bottom: 24px;

        .detail-modal-header {
            display: flex;
            flex-direction: column;

            .detail-modal-subtitle {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 12px;
                color: var(--primary-green-color);
            }

            .detail-modal-title {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--h-line-height);
                font-size: 20px;
                color: var(--gray-900-color);
                margin: 0;
            }
        }

        .detail-modal-close {
            padding: 0;
            width: 24px;
            height: 24px;
            border: 0;
            background-color: var(--white-color);
        }
    }

    .detail-modal-scroll-view {
        display: flex;
        flex-direction: column;
        width: fit-content;
        max-height: calc(100vh - 180px);
        overflow-y: auto;
        
        dl {
            width: 480px;
            margin: 0;
    
            dt {
                width: 80px;
                float: left;
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 16px;
                color: var(--gray-900-color);
                padding-right: 12px;
                margin-bottom: 8px;
            }
    
            dd {
                position: relative;
                font-family: "Pretendard Regular";
                font-weight: var(--regular-font-weight);
                line-height: var(--body-line-height);
                font-size: 16px;
                color: var(--gray-900-color);
                margin: 0 0 8px 0;
            }
    
            .detergent-detail-list-view {
                margin-left: 92px;
                display: flex;
                flex-direction: column;
                gap: 16px;
    
                .detergent-detail-list {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
    
                    .detergent-detail-item-title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 2px;
                    }
    
                    .detergent-detail-item {
                        padding: 4px 12px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;
                        background-color: var(--gray-200-color);
                        border-radius: 4px;
    
                        .detergent-detail-item-brand {
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 12px;
                            color: var(--gray-700-color);
                        }
    
                        .detergent-detail-item-name {
                            font-family: "Pretendard Regular";
                            font-weight: var(--regular-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 12px;
                            color: var(--gray-700-color);
                        }
                    }
                }
            }
        }
    }

    .modal-button {
        margin-top: 32px;
        padding: 9px;

        .button-content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 16px;
            color: var(--gray-700-color);

            &.saved {
                color: var(--primary-green-color);
            }
        }
    }
}

@media (max-width: 1023px) {

    .locate-main {
        height: 100vh;
    }
    
    .locate-view {
        top: 56px;
        height: calc(100vh - 56px);
        display: relative;
    
        .search-view {
            position: absolute;
            padding: 0;
            width: 100%;
            height: fit-content;
            box-sizing: border-box;
            z-index: 100;
            background-color: var(--white-color);
            border-bottom: 1px solid var(--black-color);

            .search-input-field {
                margin: 16px 24px;
                position: relative;
                box-sizing: border-box;
                background-color: var(--white-color);
    
                .search-input {
                    font-size: 14px;
                    width: 100%;
                    border-color: var(--gray-900-color);
                    padding: 11.5px 16px 11.5px 44px;
                    box-sizing: border-box;
                }
        
                .search-icon {
                    position: absolute;
                    top: 11px;
                    left: 16px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .search-list-view {

            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0;
            box-sizing: border-box;
            min-width: auto;
            width: 100%;
            height: 340px;
            z-index: 100;
            background-color: var(--white-color);
            border-radius: 16px 16px 0 0;
            box-shadow: 0 2px 16px 0 rgb(0, 0, 0, 0.12);
            transition: 0.5s;

            &.scroll-up {
                height: calc(100vh - 264px);
            }
    
            .filter-list-view {
                padding: 16px 0 16px 24px;
                border-bottom: 1px solid var(--gray-300-color);
    
                .filter-item {
                    text-wrap: nowrap;
    
                    &.multi-filter-item {
    
                        .dropdown-list {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
    
            .search-list-scroll {
                height: 269px;
                transition: 0.5s;

                &.scroll-up {
                    height: calc(100vh - 335px);
                }
            }
        }
    
        .search-map-view {
            position: absolute;
            top: 79px;
            height: calc(100vh - 457px);
            width: 100%;
    
            .marker-box {
    
                .marker-title {
                    font-size: 12px;
                }

                .marker-image {
                    width: 28px;
                    height: 32px;
                }
            }
        }
    }

    .detail-modal-panel {
        max-height: calc(100vh - 138px);
        width: calc(100% - 48px);
        margin: auto;
        padding: 24px;
    
        .detail-modal-headline {
    
            .detail-modal-header {
    
                .detail-modal-title {
                    line-height: var(--body-line-height);
                    font-size: 16px;
                }
            }
    
            .detail-modal-close {
                padding: 0;
                width: 24px;
                height: 24px;
                border: 0;
                background-color: var(--white-color);
            }
        }
    
        .detail-modal-scroll-view {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-height: calc(100vh - 320px);
            overflow-y: auto;
            
            dl {
                width: 100%;
                margin-bottom: 16px;
        
                dt {
                    width: auto;
                    float: none;
                    font-size: 14px;
                    padding-right: 0;
                    margin-bottom: 2px;
                }
        
                dd {
                    font-size: 14px;
                }
        
                .detergent-detail-list-view {
                    margin-left: 0;
                }
            }
        }
    
        .modal-button {
            position: relative;
            bottom: 0;
            margin-top: 24px;
        }
    }
}