.register-coupon-view {
    border-top: 2px solid var(--black-color);
    border-bottom: 1px solid var(--gray-300-color);
    background-color: var(--gray-200-color);
    display: flex;
    flex-direction: row;
    padding: 24px;
    justify-content: center;
    gap: 8px;

    .register-coupon-input {
        width: 320px;
    }
}

.coupon-list-view {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .coupon-list-filter-view {
        display: flex;
        flex-direction: row;
        gap: 4px;

        button {
            padding: 7.5px 12px;
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
            border-radius: 4px;

            &.active {
                background-color: var(--black-color);
                color: var(--white-color);
            }

            &.deactive {
                background-color: var(--white-color);
                border: 1px solid var(--gray-400-color);
                color: var(--gray-700-color);
            }
        }
    }

    .coupon-list {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        gap: 24px;
        flex-wrap: wrap;

        .coupon-list-item {
            width: calc(50% - 12px);
            
            .coupon-view {
                position: relative;
                display: flex;
                flex-direction: column;
                border: 1px solid var(--gray-300-color);
                background-color: var(--white-color);
                box-sizing: border-box;

                &.deactive {
                    border: none;
                    background-color: var(--gray-400-color);
                }

                .coupon-title {
                    font-family: "Pretendard Bold";
                    font-weight: var(--bold-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 16px;
                    color: var(--white-color);
                    padding: 8px 32px;
                    background-color: var(--black-color);
                    width: fit-content;

                    &.deactive {
                        background-color: var(--gray-600-color);
                    }
                }

                .coupon-header {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 16px;

                    .coupon-subtitle {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                        color: var(--black-color);
                        padding: 8px 32px;
                        background-color: var(--primary-green-color);
                        width: fit-content;

                        &.deactive {
                            background-color: var(--gray-500-color);
                        }
                    }

                    .coupon-status {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                        color: var(--black-color);
                        padding: 8px 32px;
                        background-color: var(--gray-300-color);
                        width: fit-content;
                    }
                }

                .coupon-qr-code {
                    position: absolute;
                    top: 12px;
                    right: 32px;
                    width: 56px;
                    height: 56px;
                }

                .coupon-desc {
                    margin: 0 32px;
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-600-color);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 42px;
                }

                .coupon-date {
                    margin: 4px 32px 32px 32px;
                    font-family: "Pretendard Bold";
                    font-weight: var(--bold-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 12px;
                    color: var(--gray-600-color);

                    .coupon-remain-date {
                        margin-left: 4px;
                        color: var(--secondary-blue-color);
                    }
                }
            }
        }
    }

    .coupon-list-empty {
        background-color: var(--gray-200-color);
        height: 380px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .coupon-list-empty-image {
            width: 200px;
            height: 150px;
            margin-bottom: 16px;
        }

        .coupon-list-empty-text {
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
            color: var(--gray-600-color);
        }
    }
}


@media (max-width: 1023px) {

    .register-coupon-view {
        display: flex;
        flex-direction: column;
        gap: 8px;
    
        .register-coupon-input {
            width: 100%;
            font-size: 16px;
            padding: 16px;
        }
    }
    
    .coupon-list-view {
        display: flex;
        flex-direction: column;
        gap: 16px;
    
        .coupon-list {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
    
            .coupon-list-item {
                width: 100%;
                .coupon-view {
                    .coupon-title {
                        padding: 8px 16px;
                    }
    
                    .coupon-header {
                        .coupon-subtitle {
                            padding: 8px 16px;
                        }
                        .coupon-status {
                            padding: 8px 16px;
                        }
                    }
    
                    .coupon-qr-code {
                        position: relative;
                        top: auto;
                        right: auto;
                        margin: auto;
                        width: calc(100% - 108px);
                        height: auto;
                        aspect-ratio: 1;
                        margin-bottom: 16px;
                    }
    
                    .coupon-desc {
                        margin: 0 24px;
                        height: 84px;
                    }
    
                    .coupon-date {
                        margin: 4px 24px 24px 24px;
                    }
                }
            }
        }
    
        .coupon-list-empty {
            height: 240px;

            .coupon-list-empty-image {
                width: 122px;
                height: 92px;
            }
        }
    }
}