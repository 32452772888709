.main {
    position: relative;
    width: 100%;
    max-height: 100vh;

    &.has-header {
        max-height: calc(100vh - 78px);
    }
}

.main-title-view {
    margin: 80px 156px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .main-title {
        margin: 0;
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 40px;
        color: var(--black-color);
    }
}

@media (max-width: 1023px) {
    .main {
        overflow-y: auto;
        box-sizing: border-box;
        &.has-header {
            max-height: calc(100vh - 56px);
            margin-top: 56px;
        }
    }

    .main-title-view {
        margin: 80px 24px 40px 24px;

        .main-title {
            font-size: 24px;
        }
    }
}