.my-page-view {

    display: flex;
    flex-direction: column;
    gap: 80px;

    .my-page-coupon-stamp-view {
        display: flex;
        flex-direction: column;
    
        .my-page-coupon-stamp-view-header {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 96px;
            background-color: var(--black-color);
            padding: 21px 24px;
            box-sizing: border-box;
            justify-content: space-between;
    
            .my-page-coupon-stamp-view-title {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 18px;
                color: var(--white-color);
            }
    
            .my-page-modify-user-button {
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-300-color);
                padding: 4px;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                gap: 2px;
                align-items: center;
                text-decoration: none;
            }
        }
    
        .my-page-coupon-stamp-body {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 256px;
            justify-content: space-between;
    
            .coupon-summary-view {
                width: 280px;
                min-width: 182px;
                box-sizing: border-box;
                padding: 24px;
                position: relative;
                background-color: var(--primary-green-color);
    
                .coupon-summary-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
    
                    .coupon-summary-title {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--h-line-height);
                        font-size: 24px;
                        color: var(--gray-900-color);
                    }
    
                    .coupon-summary-detail-button {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 4px;
                        gap: 2px;
    
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 14px;
                        color: var(--gray-800-color);
                        text-decoration: none;
                        z-index: 10;
                    }
                }
    
                .coupon-summary-body {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--h-line-height);
                    font-size: 48px;
                    color: var(--gray-900-color);
                }
            }
        }
    }
    
    .stamp-bar-view {
        min-width: 360px;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        background-color: var(--gray-200-color);
        flex: auto;
    
        .stamp-bar-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;
    
            .stamp-bar-title {
                display: flex;
                flex-direction: column;
    
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--h-line-height);
                font-size: 24px;
                color: var(--gray-900-color);
            }
    
            .stamp-history-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 4px;
                gap: 2px;
    
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-800-color);
                text-decoration: none;
            }
        }
    
        .stamp-bar-body {
            position: relative;
            height: 100%;
            width: calc(100% - 15px);
            display: flex;
            flex-direction: column;
            padding-top: 63px;

            .stamp-bar-count-view {
                position: absolute;
                top: 84px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 4px;
                z-index: 2;

                .stamp-bar-count {
                    font-family: "Pretendard Bold";
                    font-weight: var(--bold-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 12px;
                    color: var(--gray-900-color);
                }

                .stamp-bar-count-coupon {
                    position: relative;
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-900-color);
                    border-radius: 4px;
                    background-color: var(--gray-300-color);
                    padding: 1.5px 6px;
                    text-align: center;
                }

                .stamp-bar-count-coupon:after {
                    content:"";
                    position: absolute;
                    top: -5px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;
                    border-bottom: 6px solid var(--gray-300-color);
                    z-index: 1;
                }

                .stamp-bar-count-coupon-active {
                    position: relative;
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-900-color);
                    border-radius: 4px;
                    background-color: var(--primary-green-color);
                    padding: 1.5px 6px;
                    text-align: center;
                }

                .stamp-bar-count-coupon-active:after {
                    content:"";
                    position: absolute;
                    top: -5px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;
                    border-bottom: 6px solid var(--primary-green-color);
                    z-index: 1;
                }

                &.stamp-count-0 {
                    left: 0;
                }

                &.stamp-count-10 {
                    left: 33%;
                    transform: translate(-50%, 0);
                }

                &.stamp-count-15 {
                    left: 50%;
                    transform: translate(-50%, 0);
                }

                &.stamp-count-20 {
                    right: 33%;
                    transform: translate(50%, 0);
                }

                &.stamp-count-25 {
                    right: 16%;
                    transform: translate(50%, 0);
                }

                &.stamp-count-30 {
                    right: 0;
                    transform: translate(calc(50% - 5px), 0);
                }
            }
        }
    }

    .my-page-subtitle {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 32px;
        color: var(--black-color);
        margin: 0;
    }

    .my-page-desc {
        font-family: "Pretendard Regular";
        font-weight: var(--regular-font-weight);
        line-height: var(--body-line-height);
        font-size: 14px;
        color: var(--gray-800-color);
        margin-top: 4px;
        margin-bottom: 16px;
    }

    .my-page-favorite-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;

        .search-list-item {
            min-width: 300px;
            box-sizing: border-box;
            border: 1px solid var(--gray-300-color);
            border-radius: 8px;
            padding: 24px;

            .search-item-title-field {

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .search-item-title {
                    font-family: "Pretendard Bold";
                    font-weight: var(--bold-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 18px;
                    color: var(--black-color);
                    margin: 0;
                }

                .search-item-favorite {
                    width: 32px;
                    height: 32px;
                    box-sizing: border-box;
                    padding: 4px;
                    display: block;
                    z-index: 55;
                }
            }

            .search-item-desc {
                font-family: "Pretendard Regular";
                font-weight: var(--regular-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-800-color);
                margin: 0;
            }

            .search-item-detergent-list {
                display: flex;
                flex-direction: row;
                gap: 4px;
                margin-top: 4px;

                .detergent-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 2px;
                    padding: 4px 8px;
                    border-radius: 8px;
                    background-color: var(--gray-200-color);

                    .detergent-item-text {
                        font-family: "Pretendard Regular";
                        font-weight: var(--regular-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-800-color);
                    }

                    .detergent-item-count {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-800-color);
                    }
                }
            }
        }
    }

    .my-page-purchase-history {
        .my-page-purchase-history-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .purchase-history-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 4px;
                gap: 2px;
    
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-800-color);
                text-decoration: none;
            }
        }

        .purchase-history-table {
            margin-top: 16px;
            width: 100%;
            border-top: 2px solid var(--black-color);
            border-spacing: 0;
            border-collapse: collapse;
            thead {
                border-bottom: 1px solid var(--gray-900-color);
                height: 66px;
            }
            th {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
                color: var(--gray-900-color);
                text-align: center;
            }

            .purchase-date-column {
                padding: 12px 24px;
                width: 160px;
            }

            .purchase-price-column {
                padding: auto 16px;
                width: 120px;
            }

            .purchase-review-column {
                width: 76px;
            }

            .purchase-history-row {
                &.last-row {
                    border-bottom: 1px solid var(--gray-400-color);
                }
            }

            .purchase-history-row:not(.last-row) {
                border-bottom: 1px solid var(--gray-300-color);
            }

            .purchase-date-cell {
                padding: 32px 24px;
                .purchase-date-text {
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-500-color);
                }
                .purchase-spot {
                    margin-top: 4px;
                    padding: 2px 8px;
                    border-radius: 4px;
                    background-color: var(--gray-300-color); 
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 12px;
                    color: var(--gray-900-color);
                    text-overflow: ellipsis;
                }
            }

            .purchase-item-cell {
                display: flex;
                flex-direction: row;
                gap: 16px;
                height: 76px;
                align-items: center;
                margin: 16px 0;

                .purchase-item-header {
                    display: flex;
                    flex-direction: column;

                    .purchase-item-title-line {
                        display: flex;
                        flex-direction: row;
                        gap: 4px;
                        .purchase-item-title {
                            font-family: "Pretendard Bold";
                            font-weight: var(--bold-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 16px;
                            color: var(--gray-700-color);
                        }
    
                        .purchase-item-subtitle {
                            font-family: "Pretendard Regular";
                            font-weight: var(--regular-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 16px;
                            color: var(--gray-700-color);
                        }
                    }

                    .purchase-item-desc {
                        font-family: "Pretendard Regular";
                        font-weight: var(--regular-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                        color: var(--gray-700-color);
                    }
                }

                &.first-row {
                    margin-top: 32px;
                }

                &.last-row {
                    margin-bottom: 32px;
                }
            }

            .purchase-price-cell {
                font-family: "Pretendard Bold";
                font-weight: var(--bold-font-weight);
                line-height: var(--body-line-height);
                font-size: 16px;
                color: var(--gray-900-color);
                text-align: center;

                &.first-row {
                    padding-top: 16px;
                }

                &.last-row {
                    padding-bottom: 16px;
                }
            }

            .button-group {
                display: flex;
                flex-direction: column;
                width: 76px;
                height: 76px;
                gap: 4px;
                padding: 16px 0;
            }

            .table-button {
                padding: 7.5px 11px;
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 14px;
            }
        }
    }
}


@media (max-width: 1023px) {
    .my-page-view {
    
        .my-page-coupon-stamp-view {
        
            .my-page-coupon-stamp-view-header {
                flex-direction: column;
                gap: 2px;
                height: fit-content;
                padding: 24px 16px;
                justify-content: flex-start;
        
                .my-page-modify-user-button {
                    width: fit-content;
                    font-size: 12px;
                }
            }
        
            .my-page-coupon-stamp-body {
                flex-direction: column;
                width: 100%;
                height: fit-content;
                justify-content: flex-start;
        
                .coupon-summary-view {
                    width: 100%;
                    min-width: auto;
                    height: 200px;
                    padding: 24px 16px;
        
                    .coupon-summary-header {
        
                        .coupon-summary-title {
                            line-height: var(--body-line-height);
                            font-size: 18px;
                        }
        
                        .coupon-summary-detail-button {

                            font-size: 12px;
                        }
                    }
                }
            }
        }
        
        .stamp-bar-view {
            min-width: auto;
            width: 100%;
            padding: 24px 16px;
        
            .stamp-bar-header {
        
                .stamp-bar-title {
        
                    line-height: var(--body-line-height);
                    font-size: 18px;
                }
        
                .stamp-history-button {
                    font-size: 12px;
                }
            }
        
            .stamp-bar-body {
                height: fit-content;
                width: 100%;
                padding-top: 24px;
    
                .stamp-bar-count-view {
                    top: 45px;
                }

                .stamp-bar-count-list {
                    margin: 47px 0 0 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    .stamp-bar-count-list-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 12px;

                        .stamp-bar-count-list-item-title {
                            font-family: "Pretendard Regular";
                            font-weight: var(--regular-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                            color: var(--gray-700-color);
                        }

                        .stamp-bar-count-coupon {
                            position: relative;
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 12px;
                            color: var(--gray-900-color);
                            border-radius: 4px;
                            background-color: var(--gray-300-color);
                            padding: 3px 6px;
                            text-align: center;
                        }

                        .stamp-bar-count-coupon:after {
                            content:"";
                            position: absolute;
                            left: -5px;
                            top: 50%;
                            transform: translate(0, -50%);
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-right: 6px solid var(--gray-300-color);
                            z-index: 1;
                        }

                        .stamp-bar-count-coupon-active {
                            position: relative;
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 12px;
                            color: var(--gray-900-color);
                            border-radius: 4px;
                            background-color: var(--primary-green-color);
                            padding: 3px 6px;
                            text-align: center;
                        }

                        .stamp-bar-count-coupon-active:after {
                            content:"";
                            position: absolute;
                            left: -5px;
                            top: 50%;
                            transform: translate(0, -50%);
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-right: 6px solid var(--primary-green-color);
                            z-index: 1;
                        }
                    }
                }
            }
        }
    
        .my-page-subtitle {
            font-size: 20px;
        }
    
        .my-page-desc {
            font-size: 12px;
            margin-bottom: 24px;
        }
    
        .my-page-favorite-list {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 8px;
    
            .search-list-item {
                min-width: auto;
                width: 100%;
                padding: 24px 16px;
            }
        }
    
        .my-page-purchase-history {
            .my-page-purchase-history-header {
    
                .purchase-history-button {
                    font-size: 12px;
                }
            }
    
            .purchase-history-list {
                margin: 24px 0 0 0;
                padding: 0;

                .purchase-history-block {
                    display: flex;
                    flex-direction: column;
                    border-top: 2px solid var(--black-color);

                    .purchase-info-cell {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid var(--gray-300-color);

                        .purchase-date {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            padding: 16px 0;

                            .purchase-date-text {
                                font-family: "Pretendard Medium";
                                font-weight: var(--medium-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 14px;
                                color: var(--gray-500-color);
                            }

                            .purchase-spot {
                                font-family: "Pretendard Medium";
                                font-weight: var(--medium-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 12px;
                                color: var(--gray-900-color);
                                padding: 2px 8px;
                                background-color: var(--gray-300-color);
                                border-radius: 4px;
                            }
                        }

                        .button-group {
                            display: flex;
                            flex-direction: column;
                            width: fit-content;
                            height: fit-content;
                            gap: 4px;
                            padding: 16px 0;
                        }
            
                        .table-button {
                            padding: 7.5px 12px;
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                        }
                    }

                    .purchase-item-cell {
                        display: flex;
                        flex-direction: row;
                        gap: 16px;
                        align-items: center;
                        margin: 0;
                        padding: 24px 0;

                        .purchase-item-header {
                            display: flex;
                            flex-direction: column;

                            .purchase-item-title-line {
                                display: flex;
                                flex-direction: row;
                                gap: 4px;
                                .purchase-item-title {
                                    font-family: "Pretendard Bold";
                                    font-weight: var(--bold-font-weight);
                                    line-height: var(--body-line-height);
                                    font-size: 14px;
                                    color: var(--gray-700-color);
                                }
            
                                .purchase-item-subtitle {
                                    font-family: "Pretendard Regular";
                                    font-weight: var(--regular-font-weight);
                                    line-height: var(--body-line-height);
                                    font-size: 14px;
                                    color: var(--gray-700-color);
                                }
                            }
        
                            .purchase-item-desc {
                                font-family: "Pretendard Regular";
                                font-weight: var(--regular-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 12px;
                                color: var(--gray-700-color);
                            }

                            .purchase-price {
                                font-family: "Pretendard Bold";
                                font-weight: var(--bold-font-weight);
                                line-height: var(--body-line-height);
                                font-size: 14px;
                                color: var(--gray-900-color);
                            }
                        }
                    }
                }
            }
        }
    }
}