.reset-password-view {
    max-width: 480px;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font: inherit;
    position: relative;
    vertical-align: baseline;
    margin: auto;
    padding-bottom: 40px;
    box-sizing: border-box;
    min-height: calc(100vh - 257px);

    .reset-password-title {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 40px;
        color: var(--black-color);
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 80px 0;
    }

    .reset-password-desc {
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 16px;
        color: var(--black-color);
        text-align: left;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .request-verify-input-field {
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
    }

    .request-verify-button {
        margin-left: 8px;
        min-width: 90px;
    }

    .verify-code-input-field {
        margin-top: 8px;
        position: relative;
    }

    .verify-code-input {
        width: 100%;
    }

    .count-down-text {
        position: absolute;
        top: 19px;
        right: 16px;
        font-size: 16px;
        color: var(--secondary-blue-color);
    }

    .reset-password-error-text {
        margin: 8px 0;
        font-size: 14px;
    }

    .resend-verify-request {
        text-align: left;
        margin-top: 16px;
    }

    .confirm-button {
        margin-top: 16px;
    }

    .password-input-field {
        margin-top: 8px;
        position: relative;
    }

    .password-input {
        width: 100%;
    }

    .toggle-show-password-button {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .complete-view {
        height: 100vh;
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: fit-content;
    }

    .complete-image {
        margin-top: 20vh;
        width: 200px;
        height: 150px;
        margin-bottom: 16px;
    }

    .complete-message {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 40px;
        color: var(--black-color);
        margin-top: 16px;
        margin-bottom: 0;
        min-width: 481px;
    }
}

@media (max-width: 1023px) {
    .reset-password-view {
        width: calc(100% - 48px);
        min-height: calc(100vh - 246px);

        .reset-password-title {
            font-size: 24px;
            margin: 80px 0 40px 0;
        }
        
        .complete-image {
            margin-top: 82px;
            width: 120px;
            height: 90px;
        }

        .complete-message {
            line-height: var(--body-line-height);
            font-size: 18px;
            min-width: auto;
        }
    }
}