.letter-detail-view {
  padding: 80px 156px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .letter-detail-title {
      font-family: "Pretendard Bold";
      font-weight: var(--bold-font-weight);
      line-height: var(--h-line-height);
      font-size: 32px;
      color: var(--black-color);
      padding-bottom: 32px;
      width: 100%;
      border-bottom: 1px solid var(--gray-900-color);
  }

  .letter-detail-content {
      width: 100%;
      margin-top: 34px;
      border-bottom: 1px solid var(--gray-900-color);
      display: flex;
      flex-direction: column;

      .letter-detail-image {
        width: 300px;
        height: 400px;
      }

      .letter-detail-desc {
        margin: 40px 0;

        .contents-image {
          .mun-prd-list {
            display: block;
            padding: 0 0 10px;
            box-sizing: border-box;
          }

          img {
            width: 100%;
          }
        }
      }
  }

  .letter-detail-buttons {
      margin: 40px 0;
      padding: 16px auto;
      width: 480px;

      .letter-detail-button {
          width: 100%;
      }
  }
}


@media (max-width: 1023px) {

  .letter-detail-view {
    padding: 80px 24px 40px 24px;
  
    .letter-detail-title {
        margin: 0;
        line-height: var(--body-line-height);
        font-size: 18px;
    }
  
    .letter-detail-content {
        margin-top: 40px;
  
        .letter-detail-image {
          width: 100%;
          aspect-ratio: 3/4;
          height: auto;
        }
    }
  
    .letter-detail-buttons {
        width: 100%;
    }
  }
}