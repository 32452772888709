@import "layout/styles/Header.scss";
@import "layout/styles/Layout.scss";
@import "layout/styles/Footer.scss";
@import "home/styles/home.scss";
@import "login/styles/Login.scss";
@import "resetPassword/styles/ResetPassword.scss";
@import "registerUser/styles/RegisterUser.scss";
@import "locate/styles/Locate.scss";
@import "noticeBoard/styles/NoticeBoard.scss";
@import "events/styles/EventList.scss";
@import "events/styles/EventDetail.scss";
@import "letters/styles/LetterList.scss";
@import "letters/styles/LetterDetail.scss";
@import "questions/styles/Question.scss";
@import "reviews/styles/Review.scss";
@import "reviews/styles/WriteReview.scss";
@import "noticeBoard/styles/NoticeDetail.scss";
@import "myPage/styles/MyPage.scss";
@import "detergents/styles/DetergentList.scss";
@import "howToUse/styles/HowToUse.scss";
@import "introduction/styles/Introduction.scss";
@import "modifyUserInfo/styles/ModifyUserInfo.scss";
@import "purchaseHistory/styles/PurchaseHistory.scss";
@import "purchaseHistory/styles/PurchaseHistoryDetail.scss";
@import "coupons/styles/Coupon.scss";
@import "ui/styles/ui.scss";

.network-loading-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;

  .network-loading-background {
    position: absolute;
    background-color: var(--white-color);
    opacity: 0.8;
    width: 100%;
    height: 100%;
  }

  .network-loading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

li {
  list-style: none;
}

.btn-component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 2px;

  width: fit-content;
  height: fit-content;

  border-radius: 8px;
}

.list-item-full {
  width: 100%;
  margin: 0;
  text-align: center;
}

.list-item {
  margin: auto;
  text-align: center;
}

.checkbox-input-field {
  font-family: "Pretendard Medium";
  font-weight: var(--medium-font-weight);
  line-height: var(--body-line-height);
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--gray-700-color);
}

.checkbox-label {
  margin: 1.5px 0;
  font-size: 14px;
  color: var(--gray-700-color);
}

input[type="checkbox"] {
  display:none;
}

input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
}

input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 24px;
  height: 24px;
  text-align: center;
  background: url("/images/checkbox-off.svg") no-repeat;
  background-position: center center;
  box-sizing: border-box;
}

input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 24px;
  height: 24px;
  background: url("/images/checkbox-on.svg") no-repeat;
  background-position: center center;
  border-radius: 5px;
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) {
  font-family: "Pretendard Regular";
  font-weight: var(--regular-font-weight);
  line-height: var(--body-line-height);
  box-sizing: border-box;
  padding: 16px;
  font-size: 16px;
  height: fit-content;
  border: 1px solid var(--gray-400-color);
  background-color: var(--white-color);
  border-radius: 4px;
  caret-color: var(--primary-green-color);
  gap: 10px;
  text-align: left;
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i])::placeholder {
  color: var(--gray-400-color);
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]):focus-visible {
  outline: none;
  border-color: var(--gray-900-color);
  background-color: var(--white-color);
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]):disabled {
  border-color: var(--gray-400-color);
  background-color: var(--gray-200-color);
  color: var(--gray-400-color);
}

textarea {
  font-family: "Pretendard Regular";
  font-weight: var(--regular-font-weight);
  line-height: var(--body-line-height);
  box-sizing: border-box;
  padding: 16px;
  font-size: 16px;
  height: fit-content;
  border: 1px solid var(--gray-400-color);
  background-color: var(--white-color);
  border-radius: 4px;
  caret-color: var(--primary-green-color);
  gap: 10px;
  text-align: left;
  resize: none;
}

textarea::placeholder {
  color: var(--gray-400-color);
}

textarea:focus-visible {
  outline: none;
  border-color: var(--gray-900-color);
  background-color: var(--white-color);
}

textarea:disabled {
  border-color: var(--gray-400-color);
  background-color: var(--gray-200-color);
  color: var(--gray-400-color);
}

.input-title {
  font-family: "Pretendard Bold";
  font-weight: var(--bold-font-weight);
  line-height: var(--body-line-height);
  color: var(--gray-900-color);
  font-size: 16px;
  text-align: left;
  margin-bottom: 4px;

  .input-required {
    color: var(--primary-green-color);
  }
}

.input-tooltip-text {
  font-family: "Pretendard Regular";
  font-weight: var(--regular-font-weight);
  line-height: var(--body-line-height);
  font-size: 12px;
  color: var(--gray-500-color);
  margin-top: 4px;
  text-align: left;
}

.input-error-text {
  font-family: "Pretendard Regular";
  font-weight: var(--regular-font-weight);
  line-height: var(--body-line-height);
  font-size: 12px;
  color: var(--error-color);
  margin-top: 4px;
  text-align: left;
}

button {
  cursor: pointer;
}

.primary-button {
  font-family: "Pretendard Bold";
  font-weight: var(--bold-font-weight);
  line-height: var(--body-line-height);
  font-size: 16px;
  padding: 16px;
  color: var(--black-color);
  background-color: var(--primary-green-color);
  gap: 10px;
  border: 0px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  &:disabled {
    background-color: var(--gray-200-color);
    color: var(--gray-400-color);
    pointer-events: none;
  }

  &:hover {
    background-color: var(--green-70-color);
  }

  &:active {
    background-color: var(--green-70-color);
  }
}

.outline-button {
  font-family: "Pretendard Bold";
  font-weight: var(--bold-font-weight);
  line-height: var(--body-line-height);
  font-size: 16px;
  padding: 16px;
  color: var(--green-70-color);
  background-color: var(--white-color);
  gap: 10px;
  border: 1px solid var(--green-70-color);
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
}

.outline-button:disabled {
  border-color: var(--gray-300-color);
  color: var(--gray-400-color);
  pointer-events: none;
}

.outline-button:hover {
  background-color: var(--green-10-color);
}
.outline-button:active {
  background-color: var(--green-10-color);
}

.outline-gray-button {
  font-family: "Pretendard Bold";
  font-weight: var(--bold-font-weight);
  line-height: var(--body-line-height);
  font-size: 16px;
  padding: 16px;
  color: var(--gray-700-color);
  background-color: var(--white-color);
  gap: 10px;
  border: 1px solid var(--gray-400-color);
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
}

.outline-gray-button:disabled {
  border-color: var(--gray-300-color);
  color: var(--gray-400-color);
  pointer-events: none;
}

.outline-gray-button:hover {
  background-color: var(--gray-200-color);
}
.outline-gray-button:active {
  background-color: var(--gray-200-color);
}

.error-button {
  font-family: "Pretendard Bold";
  font-weight: var(--bold-font-weight);
  line-height: var(--body-line-height);
  font-size: 16px;
  padding: 16px;
  color: var(--white-color);
  background-color: var(--error-color);
  gap: 10px;
  border: 0px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  &:disabled {
    background-color: var(--gray-200-color);
    color: var(--gray-400-color);
    pointer-events: none;
  }
}

.text-link-button {
  box-sizing: border-box;
  padding: 4px;
  font-size: 14px;
  color: var(--gray-800-color);
  text-decoration: none;
}

.text-link-button-inner {
  border-bottom: 1px solid var(--gray-800-color);
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .modal-background-color {
    z-index: 200;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-900-color);
    opacity: 0.5;

    &.blocked {
      display: block;
    }
  }
}

.modal-panel {
  z-index: 201;
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  margin: auto;
  width: fit-content;
  height: fit-content;
  vertical-align: middle;
  background-color: var(--white-color);
  padding: 24px;
  box-shadow: 0 4px 8px 0 rgb(0, 0, 0, 0.12);
  border-radius: 8px;

  .modal-title {
    font-family: "Pretendard Bold";
    font-weight: var(--bold-font-weight);
    line-height: var(--h-line-height);
    font-size: 20px;
    color: var(--gray-900-color);
    margin: 0;
    margin-bottom: 24px;
  }

  .modal-desc {
    font-family: "Pretendard Medium";
    font-weight: var(--medium-font-weight);
    line-height: var(--body-line-height);
    font-size: 14px;
    color: var(--gray-700-color);
    margin-bottom: 24px;
  }

  .modal-button-group {

    display: flex;
    flex-direction: row;
    gap: 8px;

    .modal-button {
      min-width: 152px;
    }

    .modal-one-button {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.fill-gray-300-color {
  stroke: var(--gray-300-color);
}
.fill-gray-800-color {
  stroke: var(--gray-800-color);
}
.fill-green-70-color {
  stroke: var(--green-70-color);
}

img {
  display: block;
}

@media (max-width: 1023px) {
  .modal-panel {
    margin: auto;
    width: calc(100% - 48px);
    box-sizing: border-box;
  
    .modal-title {
      line-height: var(--body-line-height);
      font-size: 16px;
    }
  
    .modal-button-group {
  
      .modal-button {
        flex: 1;
        min-width: auto;
      }
  
      .modal-one-button {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .input-title {
    font-size: 14px;
  }
}