.letter-view {
  display: flex;
  flex-direction: column;
  
  .letter-list-view {
    border-top: 2px solid var(--black-color);
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    row-gap: 40px;

    .letter-list-item {
      width: calc(33.33% - 16px);
      flex-direction: column;
      text-decoration: none;

      .letter-list-item-image-view {
        margin-bottom: 12px;
        aspect-ratio: 3/4;
        box-sizing: border-box;

        .letter-list-item-image {
          width: 100%;
          height: 100%;
        }
      }

      .letter-list-item-title {
        font-family: "Pretendard Medium";
        font-weight: var(--medium-font-weight);
        line-height: var(--body-line-height);
        font-size: 18px;
        color: var(--gray-900-color);
        width: 100%;
        overflow: hidden;
        white-space: wrap;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--black-color);
      }

      .letter-list-item-summary {
        margin-top: 8px;
        font-family: "Pretendard Regular";
        font-weight: var(--regular-font-weight);
        line-height: var(--body-line-height);
        font-size: 14px;
        color: var(--gray-600-color);
        overflow: hidden;
        white-space: wrap;
      }
    }
  }
}


@media (max-width: 1023px) {

  .letter-view {
    
    .letter-list-view {
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 24px;
  
      .letter-list-item {
        width: 100%;
      }
    }
  }
}