.review-list-view {
    border-top: 2px solid var(--black-color);

    .review-list-empty {
        width: 100%;
        height: 480px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        background-color: var(--gray-200-color);

        .review-list-empty-image {
            width: 200px;
            height: 150px;
            margin-bottom: 16px;
        }

        .review-list-empty-text {
            font-family: "Pretendard Medium";
            font-weight: var(--medium-font-weight);
            line-height: var(--body-line-height);
            font-size: 14px;
            color: var(--gray-600-color);
            text-align: center;
        }
    }

    .review-container {
        border-bottom: 1px solid var(--gray-400-color);
        padding: 24px 0;
        display: flex;
        flex-direction: column;

        .review-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;

            .review-date {
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                font-size: 12px;
                color: var(--gray-500-color);
            }

            .review-modify-field {
                position: relative;
                .review-modify-button {
                    padding: 4px;
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 14px;
                    color: var(--gray-800-color);
                    text-decoration: underline;
                }
    
                .dropdown-list {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    width: 176px;
                    background-color: var(--white-color);
                    box-shadow: 2px 2px 5px 2px rgb(0, 0, 0, 0.08);
                    border-radius: 8px;
                    padding: 0;
    
                    .dropdown-text {
                        text-decoration: none;
                    }
    
                    .dropdown-menu {
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--h-line-height);
                        font-size: 16px;
                        color: var(--gray-900-color);
                        padding: 16px;
                    }

                    .dropdown-alert-menu {
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--h-line-height);
                        font-size: 16px;
                        color: var(--error-color);
                        padding: 16px;
                    }
                }
            }
        }

        .review-body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .review-preview {
                display: flex;
                flex-direction: column;
    
                .review-user-info {
                    margin-top: 4px;
                    margin-bottom: 12px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 4px;
                    .review-user-name {
                        margin: 0;
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                    }
                    .review-location {
                        padding: 2px 8px;
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 12px;
                        color: var(--gray-900-color);
                        border-radius: 4px;
                        background-color: var(--gray-300-color);
                    }
                }
    
                .review-content {
                    margin: 0;
                    font-family: "Pretendard Regular";
                    font-weight: var(--regular-font-weight);
                    line-height: var(--body-line-height);
                    font-size: 18px;
                    min-height: 40px;
                }
            }
        }
    }
}


@media (max-width: 1023px) {

    .review-list-view {
    
        .review-list-empty {
            height: 240px;
    
            .review-list-empty-image {
                width: 122px;
                height: 92px;
            }
        }
    
        .review-container {
    
            .review-header {
    
                .review-date {
                    margin: 6px 0;
                }
            }
    
            .review-body {
                display: flex;
                flex-direction: column;
                justify-content: start;
    
                .review-preview {
        
                    .review-user-info {
                        margin-top: 0;
                        margin-bottom: 8px;
                    }
        
                    .review-content {
                        margin-bottom: 16px;
                        font-family: "Pretendard Regular";
                        font-weight: var(--regular-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 16px;
                        min-height: 40px;
                    }
                }
            }
        }
    }
}