.header {
    position: relative;
    width: 100%;
    height: 78px;
    left: 0px;
    right: 0px;
    top: 0px;
    z-index: 101;

    
    padding: 0px 40px;

    background: var(--white-color);
    box-sizing: border-box;
    border-bottom: 1px solid var(--black-color);

    .header-contents {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .header-left {
            margin: auto 0;
            width: fit-content;
            float: left;
        }

        .header-logo {
            width: 202px;
            height: 30px;
        }

        .header-right {
            display: flex;
            flex-direction: row;
            align-items: end;
            padding: 0px;
            gap: 40px;

            margin: 0;
            height: 77px;
        }

        .header-menu-list {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding: 0px;
            gap: 8px;

            height: 100%;
        }

        .header-user {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            margin: auto 0;
            gap: 8px;

            width: fit-content;

            .header-login {
                text-decoration: none;
                color: var(--gray-800-color);
                font-size: 14px;
                width: fit-content;
    
                .header-login-button {
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--body-line-height);
                    background-color: var(--white-color);
                    font-size: 14px;
                    border: 0;
                    padding: 11.5px 20px;
                }
            }

            .header-logout-button {
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
                background-color: var(--white-color);
                border: 0;
                padding: 10px 16px;
                font-size: 14px;
            }

            .mypage-button {
                color: var(--gray-800-color);
                font-size: 14px;
                padding: 10px 16px;
                font-family: "Pretendard Medium";
                font-weight: var(--medium-font-weight);
                line-height: var(--body-line-height);
            }

            .header-register-button {
                padding: 11.5px 16px;
                font-size: 14px;
            }
        }

        .header-menu {

            padding: 0px 8px;

            height: 100%;
            display: flex;
            align-items: center;
            font-family: 'Pretendard Bold';
            font-weight: var(--bold-font-weight);
            line-height: var(--body-line-height);
            text-decoration: none;
            color: var(--gray-800-color);
            font-size: 16px;
            position: relative;

            .dropdown-list {
                position: absolute;
                top: 80px;
                left: 0;
                width: 176px;
                background-color: var(--white-color);
                box-shadow: 2px 2px 5px 2px rgb(0, 0, 0, 0.08);
                border-radius: 8px;
                padding: 0;

                .dropdown-text {
                    text-decoration: none;
                }

                .dropdown-menu {
                    font-family: "Pretendard Medium";
                    font-weight: var(--medium-font-weight);
                    line-height: var(--h-line-height);
                    font-size: 16px;
                    color: var(--gray-900-color);
                    padding: 16px;
                }
            }

            .header-pull-down {
                margin-left: 4px;
                width: 24px;
                height: 24px;
                fill: var(--gray-700-color);
            }
        }
    }
}

@media (max-width: 1023px) {
    .header {
        position: fixed;
        height: 56px;
        padding: 0px;

        .header-left {
            padding-left: 24px;

            .header-logo {
                width: 117px;
                height: 16px;
            }
        }

        .header-right-mobile {
            margin: auto 0px;
            padding-right: 24px;

            .header-list-button {
                padding: 4px;
                border: none;
                background-color: var(--white-color);
            }
        }

        .header-side-menu {
            position: fixed;
            display: block;
            top: 56px;
            height: calc(100vh - 56px);
            width: 0;
            background-color: var(--white-color);
            margin: 0;
            z-index: 101;

            &.opened {
                width: 100%;
            }

            .header-side-menu-view {
                overflow: auto;

                .header-menu-list {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 24px 0;
                    border-bottom: 1px solid var(--gray-300-color);

                    .accordion-arrow {
                        margin: 16px 24px;
                    }

                    .header-menu {

                        padding: 16px 24px;
                        width: 100%;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-family: 'Pretendard Bold';
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        text-decoration: none;
                        color: var(--gray-800-color);
                        font-size: 16px;
                        position: relative;
                    }

                    .dropdown-list {
                        width: 100%;
                        background-color: var(--gray-200-color);
                        margin: 0;
                        padding: 16px 0;
        
                        .dropdown-text {
                            text-decoration: none;
                        }
        
                        .dropdown-menu {
                            font-family: "Pretendard Medium";
                            font-weight: var(--medium-font-weight);
                            line-height: var(--body-line-height);
                            font-size: 14px;
                            color: var(--gray-800-color);
                            padding: 11.5px 24px;
                        }
                    }
                }

                .header-user {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
        
                    .header-login {
                        text-align: center;
                        text-decoration: none;
                        color: var(--gray-800-color);
                        font-size: 14px;
                        width: 100%;
                    }
        
                    .header-login-button {
                        width: 100%;
                        font-family: "Pretendard Medium";
                        font-weight: var(--medium-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 14px;
                        background-color: var(--white-color);
                        color: var(--gray-800-color);
                        border: 0;
                        padding: 11.5px 0;
                    }

                    .header-register-button {
                        font-family: "Pretendard Bold";
                        font-weight: var(--bold-font-weight);
                        line-height: var(--body-line-height);
                        font-size: 14px;
                        border-radius: 0;
                        padding: 11.5px;
                        width: 100%;
                        color: var(--black-color);
                    }
                }
            }
        }
    }
}