.login-view {
    max-width: 480px;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font: inherit;
    position: relative;
    vertical-align: baseline;
    margin: auto;
    box-sizing: border-box;
    min-height: calc(100vh - 257px);

    .login-title {
        font-family: "Pretendard Bold";
        font-weight: var(--bold-font-weight);
        line-height: var(--h-line-height);
        font-size: 40px;
        color: var(--black-color);
        width: 100%;
        text-align: center;
        padding: 0;
        margin: 80px 0;
    }

    .login-input-field {
        max-width: 480px;
        margin-bottom: 8px;
    }

    .login-error-text {
        margin: 8px 0;
    }

    .login-button {
        margin: 8px 0 16px 0;
    }

    .login-divider {
        margin: 32px 0;
        width: 100%;
        height: 1px;
        background-color: var(--gray-300-color);
    }

    .login-coupon-image {
        width: 280px;
        height: 180px;
    }

    .login-tooltip {
        font-size: 12px;
        color: var(--gray-600-color);
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .login-register-button {
        margin-bottom: 40px;
    }
}

@media (max-width: 1023px) {
    .login-view {
        width: calc(100% - 48px);
        min-height: calc(100vh - 246px);
        .login-title {
            font-size: 24px;
            margin: 80px 0 40px 0;
        }

        .login-input-field {
            max-width: 100%;
        }

        .login-register-button {
            margin-bottom: 40px;
        }

        .login-coupon-image {
            width: 196px;
            height: 126px;
        }
    }
}